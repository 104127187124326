import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Card } from '../Card';
import { Spread } from '../utility/Spread';
import { useClickOutside } from '../../lib/hooks/useOnClickOutside';
import Help from './assets/help.svg';
import SignOutIcon from './assets/sign-out.svg';
import { UserAvatarMenu } from './components/UserAvatarMenu';
import { NavLinks } from './components/NavLinks';
import { User } from './components/User';

export type Page = {
  name: string;
  path: string;
};

export type TopBarUIProps = {
  pages: Page[];
  user: {
    name: string;
    role: string;
    avatarUrl: string;
  };
  logoUrl: string;
  onLogoff: () => void;
};
export const TopBarUI: React.FC<TopBarUIProps> = ({ pages, user, logoUrl, onLogoff }) => {
  const [selectedMenu, setSelectedMenu] = useState<'user'>();
  const handleHide = useCallback(() => {
    setSelectedMenu(undefined);
  }, []);
  const clickOutsideRef = useClickOutside<HTMLDivElement>(handleHide);
  const menuItems = useMemo(
    () => [
      { element: <Help />, label: 'Support', action: () => console.log('HELP') },
      { element: <SignOutIcon />, label: 'Sign out', action: () => onLogoff() },
    ],
    [onLogoff],
  );
  return (
    <TopBarOuter>
      <Spread>
        <ItemGroup>
          <img src={logoUrl} alt="Logo" />
          <NavLinks pages={pages} />
        </ItemGroup>
        <ItemGroup ref={clickOutsideRef}>
          <User name={user.name} role={user.role} />
          <UserImg src={user.avatarUrl} alt="User Avatar" onClick={() => setSelectedMenu('user')} />
          <UserAvatarMenu selectedMenu={selectedMenu} user={user} menuItems={menuItems} />
        </ItemGroup>
      </Spread>
    </TopBarOuter>
  );
};

const TopBarOuter = styled(Card)`
  padding: 0 16px;
  position: relative;
`;

const UserImg = styled.img`
  border-radius: 50%;
  width: 40px;
  cursor: pointer;
`;

const ItemGroup = styled(Spread)`
  gap: 32px;
`;
