import React from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorRoute } from './routes/Error';
import { App } from './App';

const container = document.getElementById('root');
if (!container) {
  throw ErrorRoute('there is no #root element');
}
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
