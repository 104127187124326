export function getTheme() {
  return {
    colors: {
      pageBackground: '#EEF1F9',
      panelBackground: '#FFFFFF',
      normal: '#596180',
      heading: '#292B33',
      anchor: '#5170ef',
      primary: '#4c5aff',
      primaryContrast: '#FFFFFF',
    },
    fontSizes: {
      normal: '12px',
      heading: '16px',
      medium: '14px',
      large: '20px',
      extraLarge: '24px',
    },
    borderRadius: {
      normal: '8px',
      large: '16px',
    },
    buttonSize: {
      small: '8px 16px',
      medium: '10px 20px',
      large: '12px 24px',
    },
    shadow: {
      light: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
  };
}
