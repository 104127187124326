import { useMemo } from 'react';

console.warn("useSelectedLanguageCode is not implemented, will always return 'en-US'");

export function useSelectedLanguageCode(): string {
  return useMemo(() => {
    // TODO
    return 'en-US';
  }, []);
}
