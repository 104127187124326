import { type StateCreator } from 'zustand';

export type AuthStatus = 'unknown' | 'loggedIn' | 'loggingIn' | 'loggedOut' | 'loggingOut';

export type AuthStore = {
  loginStatus: AuthStatus;
  logIn: () => void;
  logOut: () => void;
};

export const auth: StateCreator<AuthStore> = (set, get) => {
  const store: AuthStore = {
    loginStatus: 'unknown',
    async logIn() {
      const { loginStatus: loginState } = get();
      if (loginState !== 'loggedOut') {
        return;
      }
      set({ loginStatus: 'loggingIn' });
      await delay(2000);
      set({ loginStatus: 'loggedIn' });
    },
    async logOut() {
      const { loginStatus: loginState } = get();
      if (loginState !== 'loggedIn') {
        return;
      }
      set({ loginStatus: 'loggingOut' });
      await delay(2000);
      set({ loginStatus: 'loggedOut' });
    },
  };
  // setTimeout(() => set({ loginStatus: 'loggedOut' }), 500);
  return store;
};

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
