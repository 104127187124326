import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { GlobalWrapper } from './GlobalWrapper';
import { ZustandStoresProvider } from '../appState/StoresProvider';
import { fontFaces } from './fontFaces';
import { useFontFaces } from '../lib/hooks/useFontFaces';
import { getRouter } from '../getRouter';
import { getTheme } from '../getTheme';

const router = getRouter();
const theme = getTheme();

export const App: React.FC = () => {
  useFontFaces(fontFaces, true);
  return (
    <ZustandStoresProvider>
      <GlobalWrapper theme={theme}>
        <RouterProvider router={router} />
      </GlobalWrapper>
    </ZustandStoresProvider>
  );
};
