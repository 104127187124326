import { Overview } from './components/Overview/Overview';
import { Leaderboard } from './routes/Leaderboard';
import { Activity } from './routes/Activity';
import type React from 'react';

/**
 * An array of objects representing the pages in the top bar menu.
 * Each object contains the following properties:
 * - `path`: A string representing the URL path of the page.
 * - `name`: A string representing the display name of the page.
 * - `element`: A React element representing the component to be rendered for the page.
 */
export const topbarMenuPages: {
  path: string;
  name: string;
  element: React.ReactNode;
}[] = [
  { path: 'overview', name: 'Overview', element: <Overview /> },
  { path: 'leaderboard', name: 'Leaderboard', element: <Leaderboard /> },
  { path: 'activity', name: 'Challenges & Activity', element: <Activity /> },
];
