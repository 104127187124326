import { useEffect } from 'react';
import { type FontFaceDescriptor } from './FontFaceDescriptor';

export const useFontFaces = (fontDescriptors: FontFaceDescriptor[], preload?: boolean): void => {
  useEffect(() => {
    const unload = loadFontFaces(fontDescriptors, preload);
    return () => unload();
  }, [fontDescriptors, preload]);
};

const attachFontFaceCSS = (fontDescriptors: FontFaceDescriptor[]): (() => void) => {
  const fontFaceCSS = fontDescriptors.map(createFontFaceCSSRule).join('\n');
  const styleElement = document.createElement('style');
  styleElement.textContent = fontFaceCSS;
  document.head.appendChild(styleElement);
  return () => {
    document.head.removeChild(styleElement);
  };
};
const forcePreload = (fontDescriptors: FontFaceDescriptor[]): (() => void) => {
  const div = createOffscreenDiv();
  const spans = fontDescriptors.map(createSpanWithFontStyle);
  spans.forEach((span) => div.appendChild(span));
  document.body.appendChild(div);
  return () => {
    document.body.removeChild(div);
  };
};
const createFontFaceCSSRule = (fd: FontFaceDescriptor): string => `
    @font-face {
        font-family: ${fd.family};
        src: url(${fd.src}) format('${fd.format}');
        font-weight: ${fd.weight};
        font-style: ${fd.style};
    }
`;
const createOffscreenDiv = (): HTMLDivElement => {
  const div = document.createElement('div');
  div.style.position = 'absolute';
  div.style.top = '-1000px';
  div.style.left = '-1000px';
  return div;
};
const createSpanWithFontStyle = (fd: FontFaceDescriptor): HTMLSpanElement => {
  const span = document.createElement('span');
  span.style.fontFamily = fd.family;
  span.style.fontStyle = fd.style;
  span.style.fontWeight = fd.weight;
  return span;
};
function loadFontFaces(fontDescriptors: FontFaceDescriptor[], preload: boolean | undefined): () => void {
  const removeFontFaces = attachFontFaceCSS(fontDescriptors);
  if (preload) {
    const unPreload = forcePreload(fontDescriptors);
    return () => {
      removeFontFaces();
      unPreload();
    };
  }
  return () => {
    removeFontFaces();
  };
}
