import type React from 'react';
import { useMemo } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import styled from 'styled-components';

export const ErrorRoute: React.FC = () => {
  const error = useRouteError();
  console.error({ error });

  const message = useMemo(
    () => (isRouteErrorResponse(error) ? error.statusText : error instanceof Error ? error.message : 'Unknown Error'),
    [error],
  );
  return (
    <Container>
      <div>
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <h2 className="msg">
          <p>{message}</p>
        </h2>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  place-items: center;
  min-height: 100%;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    > .msg {
      margin-top: 1em;
      font-weight: 600;
    }
  }
  text-align: center;
  padding: 8px;
`;
