import React, { useMemo, type PropsWithChildren } from 'react';
import { StyleSheetManager, ThemeProvider, type DefaultTheme } from 'styled-components';
import rtlPlugin from 'stylis-plugin-rtl';
import { GlobalStyles } from './GlobalStyles';
import { WrapHelmet } from './WrapHelmet';
import { useIsRTLLayout } from '../appState/hooks/useIsRTLLayout';

export const GlobalWrapper: React.FC<PropsWithChildren<{ theme: DefaultTheme }>> = ({ children, theme }) => {
  const stylisPlugins = useStylisPlugins();
  return (
    <ThemeProvider theme={theme}>
      <WrapHelmet>
        <StyleSheetManager stylisPlugins={stylisPlugins}>
          <GlobalStyles />
          {children}
        </StyleSheetManager>
      </WrapHelmet>
    </ThemeProvider>
  );
};
function useStylisPlugins() {
  const isRTLLayout = useIsRTLLayout();
  const stylisPlugins = useMemo(() => (isRTLLayout ? [rtlPlugin] : []), [isRTLLayout]);
  return stylisPlugins;
}
