import { createContext, useContext, useRef, type PropsWithChildren } from 'react';
import { createStore, useStore, type StateCreator, type StoreApi } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export type StoreTypes = Record<string, unknown>;
export type Creators<T extends StoreTypes> = { [K in keyof T]: StateCreator<T[K]> };
export type Stores<T extends StoreTypes> = { [K in keyof T]: StoreApi<T[K]> };

type CreateZustandStoresContextProps<T extends StoreTypes> = {
  stateCreators: Creators<T>;
  persistKeys?: (keyof T)[];
  init?: (stores: Stores<T>) => void;
};

export function createZustandStoresContext<T extends StoreTypes>({
  stateCreators,
  persistKeys: persistKeys,
  init,
}: CreateZustandStoresContextProps<T>): {
  useZustandStore: <K extends keyof T>(storeName: K) => T[K];
  ZustandStoresProvider: React.FC<{
    children?: React.ReactNode;
  }>;
} {
  const StoresContext = createContext<Stores<T> | undefined>(undefined);
  const ZustandStoresProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const storeRef = useRef<Stores<T>>();
    if (!storeRef.current) {
      storeRef.current = Object.fromEntries(
        Object.entries(stateCreators).map(([name, stateCreator]) => {
          const creator = persistKeys?.includes(name as keyof T)
            ? persist(stateCreator, { name: `zustand-${name}`, storage: createJSONStorage(() => sessionStorage) })
            : stateCreator;
          const store = createStore(creator);
          return [name, store] as const;
        }),
      ) as Stores<T>;
      init?.(storeRef.current);
    }
    return <StoresContext.Provider value={storeRef.current}>{children}</StoresContext.Provider>;
  };
  function useZustandStore<K extends keyof T>(storeName: K): T[K] {
    const stores = useContext(StoresContext);
    if (!stores) {
      throw new Error('Missing StoreProvider');
    }
    const store = stores[storeName];
    return useStore(store);
  }
  return { useZustandStore, ZustandStoresProvider };
}
