import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { TopBar } from '../components/TopBar';

export const MainLayout: React.FC = () => {
  return (
    <Layout>
      <TopBar />
      <div>
        <Outlet />
      </div>
    </Layout>
  );
};
const Layout = styled.div`
  display: grid;
  grid-template-rows: min-content 1fr;
  gap: 12px;
  min-height: 100%;
  max-width: 1600px;
  margin: 0 auto;
`;
