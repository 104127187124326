import { type ChangeEvent, type FC, type FormEvent, useState } from 'react';
import styled from 'styled-components';
import InfoIcon from './assets/InfoIcon.svg';
import { Button } from '../Button';
import { TextInput } from '../TextInput';

type LoginComponentProps = {
  onSubmit: (email: string) => void;
  guidanceText?: string;
};

const DEFAULT_GUIDANCE_TEXT = "If you can't login, contact your manager or a colleague with Administrator rights.";

export const LoginForm: FC<LoginComponentProps> = ({ onSubmit, guidanceText }: LoginComponentProps) => {
  const [email, setEmail] = useState('');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit(email);
  };

  return (
    <CardContainer>
      <FormWrapper>
        <Title>OutThink</Title>
        <SubTitle>CYBERIQ SIGN IN</SubTitle>
        <Form onSubmit={handleSubmit}>
          <TextInput label="Email" placeholder="Enter your email" onChange={handleInputChange} />
          <Button label="Next" type="submit" />
        </Form>
        <HelperText>
          <InfoIcon />
          {guidanceText ?? DEFAULT_GUIDANCE_TEXT}
        </HelperText>
      </FormWrapper>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.panelBackground};
  padding: 80px 32px;
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  max-width: 350px;
  width: 100%;
  height: 100%;
`;

const FormWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.extraLarge};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.heading};
  margin: 0;
`;

const SubTitle = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.extraLarge};
  color: ${({ theme }) => theme.colors.normal};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const HelperText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  color: ${({ theme }) => theme.colors.normal};
  display: grid;
  grid-template-columns: 16px 1fr;
  align-items: center;
  gap: 12px;
`;
