import { transparentize } from 'polished';
import styled from 'styled-components';

export const UserMenu = styled.div<{
  $isHidden: boolean;
}>`
  transition:
    opacity 0.3s,
    transform 0.3s;
  opacity: ${({ $isHidden: isHidden }) => (isHidden ? 0 : 1)};
  transform: ${({ $isHidden: isHidden }) => (isHidden ? 'translateY(-10px)' : 'translateY(0)')};
  font-size: 12px;
  min-width: 200px;
  right: 0;
  padding: 16px 20px;
  cursor: auto;
  top: calc(100% + 6px);
  transform-origin: top right;
  position: absolute;
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  border: 1px solid ${({ theme }) => transparentize(0.7, theme.colors.normal)};
  background-color: ${({ theme }) => theme.colors.panelBackground};
  z-index: 900;
  max-height: 75vh;
  overflow: hidden auto;
  box-shadow: 1px 2px 3px 0 #0c17360f;
  pointer-events: ${({ $isHidden: isHidden }) => (isHidden ? 'none' : 'auto')};
  user-select: none;
`;
