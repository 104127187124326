import { type ButtonHTMLAttributes, type FC } from 'react';
import styled from 'styled-components';

type ButtonProps = {
  label: string;
  onClick?: () => void;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: FC<ButtonProps> = ({
  label,
  onClick,
  disabled = false,
  size = 'medium',
  ...props
}: ButtonProps) => (
  <StyledButton onClick={onClick} disabled={disabled} size={size} {...props}>
    {label}
  </StyledButton>
);

const StyledButton = styled.button<{
  size: 'small' | 'medium' | 'large';
  disabled?: boolean;
}>`
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primaryContrast};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  background-color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ size, theme }) =>
    size === 'small' ? theme.fontSizes.normal : size === 'large' ? theme.fontSizes.large : theme.fontSizes.medium};
  padding: ${({ size, theme }) => theme.buttonSize[size]};

  &:hover {
    filter: brightness(0.9);
  }
`;
