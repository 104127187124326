import { useMemo } from 'react';
import { useSelectedLanguageCode } from './useSelectedLanguageCode';
import { getTwoLetterLangCode } from '../../lib/language/getTwoLetterLangCode';

const rtlLanguages = new Set(['ar', 'he']);

export const useIsRTLLayout = (): boolean => {
  const language = useSelectedLanguageCode();
  const twoLetterCode = useMemo(() => getTwoLetterLangCode(language), [language]);
  const isRTL = useMemo(() => !!twoLetterCode && rtlLanguages.has(twoLetterCode), [twoLetterCode]);
  return isRTL;
};
