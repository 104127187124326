import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * A custom React hook designed to manage and preserve specific URL search parameters across
 * component re-renders. This hook takes an array of strings, `toPreserve`, which specifies
 * the keys of the search parameters that need to be preserved.
 *
 * @param toPreserve - An array of search parameter keys to preserve.
 *
 * This hook ensures that the specified search parameters are preserved in the URL
 * and in the component state. It compares the current search parameters with the
 * preserved parameters and updates them if necessary.
 *
 * The hook uses the following steps:
 * 1. Extracts the entries of the current search parameters.
 * 2. Filters the entries to keep only those that are specified in the `toPreserve` array.
 * 3. Compares the filtered search parameters with the preserved parameters.
 * 4. Updates the search parameters in the URL if there are discrepancies.
 * 5. Updates the preserved parameters in the component state if there are discrepancies.
 *
 */
export function usePreserveSearchParams(toPreserve: string[]): void {
  const [searchParams, setSearchParams] = useSearchParams();
  const [preservedParams, setPreservedParams] = useState<[string, string][]>([]);
  const searchParamsEntriesToPreserve = useMemo(() => {
    const e = [...searchParams.entries()];
    return e.filter(([k]) => toPreserve.includes(k));
  }, [searchParams, toPreserve]);
  useEffect(() => {
    const spToPreserveVsPreservedParam = toPreserve.map((key) => ({
      key,
      valueFromSearchParams: searchParamsEntriesToPreserve.find(([k]) => k === key),
      valueFromPreservedParams: preservedParams.find(([k]) => k === key),
    }));
    if (
      spToPreserveVsPreservedParam.some(
        ({ valueFromSearchParams, valueFromPreservedParams }) =>
          valueFromPreservedParams &&
          (!valueFromSearchParams || valueFromSearchParams[1] !== valueFromPreservedParams[1]),
      )
    ) {
      setSearchParams((p) => {
        const newSearchParams = new URLSearchParams(p);
        spToPreserveVsPreservedParam.forEach(({ key, valueFromPreservedParams, valueFromSearchParams }) => {
          if (
            valueFromPreservedParams &&
            (!valueFromSearchParams || valueFromSearchParams[1] !== valueFromPreservedParams[1])
          ) {
            newSearchParams.set(key, valueFromPreservedParams[1]);
          }
        });
        return newSearchParams;
      });
    }
    if (
      spToPreserveVsPreservedParam.some(
        ({ valueFromSearchParams, valueFromPreservedParams }) =>
          valueFromSearchParams &&
          (!valueFromPreservedParams || valueFromPreservedParams[1] !== valueFromSearchParams[1]),
      )
    ) {
      setPreservedParams((p) => {
        const s = [
          ...new Map([
            ...p,
            ...spToPreserveVsPreservedParam
              .filter(
                ({ valueFromSearchParams, valueFromPreservedParams }) =>
                  valueFromSearchParams &&
                  (!valueFromPreservedParams || valueFromPreservedParams[1] !== valueFromSearchParams[1]),
              )
              .map(({ key, valueFromSearchParams }) => [key, valueFromSearchParams?.[1]] as [string, string]),
          ]).entries(),
        ];
        return s;
      });
    }
  }, [searchParamsEntriesToPreserve, preservedParams, setSearchParams, toPreserve]);
}
