import styled from 'styled-components';

export const User: React.FC<{ name: string; role: string }> = ({ name, role }) => {
  return (
    <UserContainer>
      <UserName>{name}</UserName>

      <p>{role}</p>
    </UserContainer>
  );
};
const UserName = styled.p`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.heading};
`;
const UserContainer = styled.div``;
