import { type FC } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { LoginForm } from '../LoginForm';
import fullBackgroundImage from './assets/full-image-background.png'; // TODO: Remove after data fetching is implemented
import splitBackgroundImage from './assets/split-image-background.png'; // TODO: Remove after data fetching is implemented
import { useZustandStore } from '../../appState/StoresProvider';
import { type LoginTheme, useLoginTheme } from '../../appState/hooks/useLoginTheme';

export const Login: FC = () => {
  const { tenantId } = useParams();
  const { logIn } = useZustandStore('auth');
  const loginTheme: LoginTheme = useLoginTheme();

  const handleLogin = (email: string) => {
    console.log('Email submitted:', email, tenantId);
    logIn();
  };

  return (
    <LoginContainer loginTheme={loginTheme}>
      <LoginContent>
        <LoginForm onSubmit={handleLogin} />
        {loginTheme === 'split' && splitBackgroundImage ? (
          <ImageWrapper backgroundImage={splitBackgroundImage} />
        ) : null}
      </LoginContent>
    </LoginContainer>
  );
};

const LoginContainer = styled.div<{ loginTheme: 'split' | 'full' }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  background: ${({ loginTheme, theme }) =>
    loginTheme === 'full' ? `url(${fullBackgroundImage})` : theme.colors.pageBackground};
  background-size: cover;
`;

const LoginContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.panelBackground};
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  height: 500px;
`;

const ImageWrapper = styled.div<{ backgroundImage: string }>`
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  border-radius: 0 ${({ theme }) => theme.borderRadius.normal} ${({ theme }) => theme.borderRadius.normal} 0;
  width: 350px;
  height: 100%;
`;
