import { UserMenu } from './UserMenu';
import type { ReactNode } from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';

export type UserAvatarMenuProps = {
  selectedMenu: string | undefined;
  user: {
    name: string;
    role: string;
    avatarUrl: string;
  };
  menuItems: {
    element: ReactNode;
    label: string;
    action: () => void;
  }[];
};

export const UserAvatarMenu: React.FC<UserAvatarMenuProps> = ({ selectedMenu, user, menuItems }) => {
  return (
    <UserMenu $isHidden={selectedMenu !== 'user'}>
      <UserMenuSection>
        <h3>{user.name}</h3>
      </UserMenuSection>
      <UserMenuSection>
        <MenuSectionItems>
          {menuItems.map(({ element, label, action }) => (
            <MenuSectionItem key={label} onClick={action}>
              {element}
              <span>{label}</span>
            </MenuSectionItem>
          ))}
        </MenuSectionItems>
      </UserMenuSection>
    </UserMenu>
  );
};

const MenuSectionItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const MenuSectionItem = styled.button`
  all: unset;
  cursor: pointer;
  display: grid;
  grid-template-columns: 16px max-content;
  gap: 8px;
  align-items: center;
  color: ${({ theme }) => theme.colors.heading};
  width: max-content;
`;

const UserMenuSection = styled.div`
  &:not(:last-child) {
    padding-bottom: 8px;
    border-bottom: 1px solid ${({ theme }) => transparentize(0.5, theme.colors.normal)};
  }
  &:not(:first-child) {
    padding-top: 8px;
  }
`;
